 $(function(){
	if($(".nav-tabs .nav-link").length > 0){
		function addTabToPagination(){
			if($(".pagination").length > 0){
				$(".pagination > li > a").unbind().click(function(){
					let url = $(this).attr("href")+window.location.hash;
					$(this).attr("href",url);
				});
			}
		}
		
		function setTab(){
			$(".nav-tabs .nav-link.active").removeClass("active");
			$(".nav-tabs .nav-link[data-bs-target='"+window.location.hash+"']").addClass("active");
			$(".tab-content .tab-pane.active").removeClass("active");
			$(".tab-content .tab-pane[id='"+window.location.hash.replace("#","")+"']").addClass("active");
		}
		
		$(".nav-tabs .nav-link").click(function(){
			window.location.hash = $(this).data("bs-target");
			localStorage.setItem("tab-"+route_alias,window.location.hash);
			addTabToPagination();
		});
		if(window.location.hash != ""){
			setTab();
			addTabToPagination();
		}else{
			if (localStorage.hasOwnProperty("tab-"+route_alias)) {
				let tabHash = localStorage.getItem("tab-"+route_alias);
				window.location.hash = tabHash;
				setTab();
			}
		}
	}
})